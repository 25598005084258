@import '/app/src/theme/variables.module.less';

.consent-cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 32px 0;
  background: #fff;
  box-shadow:
    0 2px 6px rgba(19 37 48 / 18%),
    0 0 18px rgba(19 37 48 / 13%);
  &-buttons {
    margin-top: 15px;
  }
  &-button {
    margin-bottom: 10px;
  }
}
.consent-cookies-content {
  &-title {
    margin-bottom: 12px;
  }
}
