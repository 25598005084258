@import '/app/src/theme/variables.module.less';

.touch {
  position: relative;
  z-index: 50;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1920px;
  background-color: @neutral-9;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: none;
    width: 100%;
    height: 100%;
    content: "";
  }
  &.tablet {
    &::before {
      display: block;
      background: linear-gradient(116.11deg, @neutral-9 14.61%, rgb(19 37 48 / 0%) 47.1%);
    }
  }
  &.mobile {
    &::before {
      display: block;
      background: linear-gradient(@neutral-9 40%, rgb(19 37 48 / 0%) 100%);
    }
  }
}
.content {
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 96px;
  padding-bottom: 96px;
  &.tablet {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  &.mobile {
    display: block;
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
.touch-meta-col-tablet {
  padding-top: 61px;
}
.touch-meta {
  &.mobile {
    padding-bottom: 28px;
  }
}
.desc {
  padding-top: 24px;
  padding-left: 12px;
  margin-bottom: 0;

  color: @main-gray-3;
  list-style: none;
  &.tablet {
    padding-top: 16px;
    padding-left: 12px;
    margin-bottom: 0;

    color: @main-gray-3;
  }
  &.mobile {
    padding-top: 16px;
    padding-left: 0;
    margin-bottom: 0;

    color: @main-gray-3;
    text-align: center;
  }
}
.desc-item {
  position: relative;
  &::before {
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-right: 12px;
    margin-bottom: 4px;

    vertical-align: middle;
    content: "";
    background-color: @main-gray-3;
    border-radius: 50%;
  }
}
