@import '/app/src/theme/variables.module.less';

@table-padding-vertical: 15px;
@table-padding-horizontal: 8px;
@table-header-bg: @blue-1;
@table-font-size: 14px;
@table-header-color: @neutral-9;
@table-border-color: @main-blue-gray-3;
@main-orange-8: #f57e2e;
@main-blue-gray-1: #f9fafb;
@orange-8: #f47d2f;
@gray-1: #f9fafb;
@gray-8: #444f54;
@main-gray-9: #132530;
@table-sort-arrow: #0068d7;
@blue-2: #f1f4f6;
.table-wrapper {
  width: 100%;
  :global {
    .ant-table {
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: @main-orange-8;
        border-color: @main-orange-8;
      }
      .ant-checkbox .ant-checkbox-inner {
        border-color: @main-orange-8;
        border-radius: 3px;
      }
      .ant-checkbox-checked::after {
        border-color: @main-orange-8;
        border-radius: 3px;
      }
    }
    .ant-table-thead > tr > th {
      font-size: 12px;
      font-weight: 600;
      border: 0;
    }
    .ant-table-body {
      overflow: auto;
    }
    .ant-table-tbody > tr.ant-table-row-selected > td {
      background-color: @main-blue-gray-1;
    }
    .ant-table-tbody > tr.ant-table-expanded-row > td,
    tr.ant-table-expanded-row:hover > td {
      background-color: unset;
    }
    .ant-spin {
      color: @orange-8;
      .ant-spin-dot-item {
        background-color: @orange-8;
      }
    }
    .ant-table-summary .ant-table-cell {
      font-weight: 600;
      line-height: 24px;
      background: @blue-2;
    }
    .ant-table.ant-table-middle {
      font-size: 14px;
      .ant-table-cell {
        line-height: 22px;
        color: @main-gray-9;
      }
    }
    .table-cell-align-top {
      .ant-table .ant-table-cell {
        vertical-align: top;
      }
    }
    .ant-table-middle .ant-table-thead .ant-table-cell {
      padding: 8px;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
      color: @main-gray-9;
      background: @gray-1;
      .ant-table-column-sorters {
        padding: 8px;
      }
    }
    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
      color: @table-sort-arrow;
    }
  }
  &.without-gutters {
    padding: 0;
  }
  &.table-cell-align {
    &-top {
      :global {
        .ant-table-tbody .ant-table-cell {
          vertical-align: top;
        }
      }
    }
    &-middle {
      :global {
        .ant-table-tbody .ant-table-cell {
          vertical-align: middle;
        }
      }
    }
    &-bottom {
      :global {
        .ant-table-tbody .ant-table-cell {
          vertical-align: bottom;
        }
      }
    }
  }
  &.table-cell-disabled {
    :global {
      .ant-table-tbody .ant-table-cell {
        height: inherit;
      }
    }
  }
  &.table-type {
    &-common {
      color: inherit;
    }
    &-description {
      color: inherit;
      :global {
        .ant-table-tbody .ant-table-cell {
          height: 54px;
        }
        .ant-table-thead .ant-table-cell {
          height: 46px;
          font-size: 16px;
          line-height: 24px;
          &::before {
            display: none;
          }
        }
      }
      &.mobile {
        :global {
          .ant-table-tbody .ant-table-cell {
            font-size: 12px;
            line-height: 20px;
          }
          .ant-table-thead .ant-table-cell {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
    &-products-listing {
      :global {
        .ant-table-container {
          min-height: 50vh;
          ::-webkit-scrollbar {
            height: 4px;
            appearance: none;
          }
          ::-webkit-scrollbar-track {
            background: @main-gray-2;
            border-radius: 4px;
          }
          ::-webkit-scrollbar-thumb {
            background: @main-gray-4;
            border-radius: 4px;
          }
          ::-webkit-scrollbar-thumb:hover {
            background: @main-gray-6;
          }
        }
      }
    }
  }
}
