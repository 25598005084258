@import '/app/src/theme/variables.module.less';

.leads {
  width: 100%;
  padding: 56px 0;
  &.tablet {
    padding-top: 36px;
    padding-bottom: 36px;
  }
  &.mobile {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
.lead-header-mobile {
  margin-bottom: 16px;
}
.leads-type {
  &-base {
    background-color: @dark;
  }
  &-primary {
    background-color: @main-orange-10;
  }
}
