@import '/app/src/theme/variables.module.less';

.feedback {
  width: 100%;
  max-width: 1920px;
}
.top {
  &.mobile {
    padding: 32px 0;
  }
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 120px 0;
  &.tablet {
    padding: 48px 0;
  }
  &.mobile {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.title {
  margin-bottom: 16px;
  &-mobile {
    margin-bottom: 8px;
  }
}
.details {
  display: flex;
  flex: 0 1 100%;
  flex-direction: column;
  justify-content: space-between;
}
.images {
  height: inherit;
}
.carousel-img {
  position: relative;
  width: 100%;
  min-height: 660px;
  vertical-align: middle;
  &.tablet {
    min-height: 660px;
  }
  &.mobile {
    min-height: 297px;
  }
}
.carousel-btns {
  display: inline-block;
  vertical-align: top;
  &-next {
    margin-left: 16px;
  }
  &-mobile {
    margin-top: 24px;
    text-align: center;
  }
}
