@import '/app/src/theme/variables.module.less';

.phone {
  &:global {
    &.ant-input-group {
      display: table;
      > :first-child {
        display: table-cell;
        width: 1px;
      }
    }
  }
}
