@import '/app/src/theme/variables.module.less';

.retailer-video {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 716px;
  &.tablet {
    min-height: 424px;
  }
  &.mobile {
    min-height: 210px;
  }
  &-preview {
    position: absolute;
    z-index: 40;
    display: block;
    width: 100%;
    height: 100%;
  }
}
