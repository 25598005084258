@import '/app/src/theme/variables.module.less';

.video {
  width: 100%;
  max-width: 1920px;
  padding-top: 78px;
  padding-bottom: 78px;
  &.tablet {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  &.mobile {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
