@import '/app/src/theme/variables.module.less';

@neutral-10: #131c20;
.retailer {
  width: 100%;
  max-width: 1920px;
  background-color: @neutral-10;
  &-brands-content {
    padding-top: 66px;
    &-tablet {
      padding-top: 16px;
    }
    &-mobile {
      padding-top: 16px;
    }
  }
}
.content {
  padding-top: 120px;
  padding-bottom: 70px;
  &-tablet {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  &-mobile {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
.image {
  position: relative;
  width: 100%;
  height: 100%;
  &.mobile {
    min-height: 376px;
  }
}
.retailer-text-mobile {
  padding-top: 8px;
}
.head {
  margin-bottom: 32px;
  &-mobile {
    margin-bottom: 24px;
  }
}
.title {
  margin-bottom: 32px;
  &-mobile {
    margin-bottom: 24px;
  }
}
