@import '/app/src/theme/variables.module.less';

.main {
  position: relative;
  display: flex;
  flex: 1 0 0;
  width: 100%;
  min-height: auto;
  background-color: @white;
}
.row {
  height: 100%;
}
.inner {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 64px 0;
  &.private {
    flex-direction: column;
    align-items: initial;
    justify-content: initial;
    padding-top: 40px;
    padding-bottom: 64px;
  }
  &.navigate {
    flex-direction: column;
    align-items: initial;
    justify-content: initial;
    padding-top: 4px;
    padding-bottom: 64px;
  }
  &.no-gutters {
    padding: 0;
  }
}
