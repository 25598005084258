@import '/app/src/theme/variables.module.less';

.help-text {
  padding-top: 8px;
}
.help-text-gutter {
  &-bottom {
    padding: 0;
    padding-bottom: 24px;
  }
}
