@import '/app/src/theme/variables.module.less';

.list-item {
  display: block;
  padding: 0;
  :global {
    .ant-list-item-meta-content {
      width: auto;
      color: @neutral-9;
      text-align: left;
    }
    .ant-list-item-meta-title {
      font-size: 16px;
      line-height: 16px;
      color: @neutral-9;
    }
    .ant-list-item-meta-description {
      font-size: 14px;
      line-height: 24px;
      color: @neutral-6;
    }
  }
  &-meta {
    display: flex;
    align-items: center;
    margin: 24px 0;
    &.mobile {
      margin: 16px 0;
      :global {
        .ant-list-item-meta-content {
          text-align: center;
        }
        .ant-list-item-meta-description {
          font-size: 12px;
        }
      }
    }
  }
}
.list-item-header {
  margin-bottom: 24px;
  &-tablet {
    margin-bottom: 16px;
  }
  &-mobile {
    margin-bottom: 16px;
  }
}
.list-item-desc {
  margin-top: 8px;
  margin-bottom: 24px;
  &-tablet {
    margin-top: 8px;
    margin-bottom: 24px;
  }
  &-mobile {
    margin-bottom: 16px;
    text-align: center;
  }
}
