@import '/app/src/theme/variables.module.less';

.wrapper-node {
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  word-break: break-word;
  &.disabled {
    color: @main-gray-4;
  }
}
