@import '/app/src/theme/variables.module.less';

.header {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 0;
  background-color: #fff;
}
.header-inner {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  height: fit-content;
  padding: 28px 0;
  line-height: 1;
}
