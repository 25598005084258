@import '/app/src/theme/variables.module.less';

@blue-1: #f9fafb;
.lead-signup-form {
  background: @blue-1;
  border-radius: 6px;
}
.lead-signup-form-size {
  &-middle {
    padding: 24px;
  }
  &-large {
    padding: 32px;
  }
}
.consent-checkbox {
  margin-bottom: 24px;
}
.actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
