@import '/app/src/theme/variables.module.less';

@white: #ffffff;
@dark: #131c20;
@main-gray: #21292d;
@main-gray-second: #2b3236;
@main-orange-8: #f57e2e;
@main-orange-10: #f85a00;
.freedom {
  width: 100%;
  max-width: 1920px;
  height: auto;
  &-overview {
    width: 100%;
    height: 100%;
    color: @white;
    &-cell {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      aspect-ratio: 1 / 1;
      padding: 8px;
      overflow: hidden;
      background: @white;
    }
    &-cell-wide {
      aspect-ratio: 2 / 1;
    }
    &-cell-full {
      aspect-ratio: 3 / 1;
    }
    .cell-order {
      background: @main-gray;
    }
    .cell-pro {
      padding: 54px;
      background: @dark;
      &.mobile {
        padding: 28px;
      }
    }
    .cell-leaf {
      padding: 40px;
      background: @white;
      &.mobile {
        padding: 24px;
      }
    }
    .cell-strings {
      background: @main-gray-second;
    }
    .cell-title {
      background: repeating-linear-gradient(
        -45deg,
        @main-orange-10,
        @main-orange-10 12%,
        @main-orange-8 12%,
        @main-orange-8 25%
      );
    }
  }
}
