@import '/app/src/theme/variables.module.less';

.consent-cookies-form {
  padding-top: 20px;
  .checkboxes {
    display: flex;
    gap: 24px;
  }
  .action {
    margin-bottom: 8px;
  }
}
