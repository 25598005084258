@import '/app/src/theme/variables.module.less';

@main-gray-3: #e7e9ea;
@dark: #131c20;
.hero {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  min-height: 664px;
  text-align: left;
  background-color: @dark;
  &.tablet {
    min-height: 526px;
  }
  &.mobile {
    min-height: 694px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.form {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 222px 0 120px;
  &.tablet {
    margin: 142px 0 78px;
  }
  &.mobile {
    margin: 88px 0 32px;
  }
}
.meta-mobile {
  padding-bottom: 24px;
}
.desc {
  padding-top: 24px;
  padding-left: 32px;
  margin-bottom: 0;
  color: @main-gray-3;
  &-tablet {
    padding-top: 16px;
    padding-left: 32px;
    margin-bottom: 0;
    color: @main-gray-3;
  }
  &-mobile {
    padding-top: 16px;
    padding-left: 0;
    margin-bottom: 0;
    color: @main-gray-3;
    text-align: center;
    list-style: none;
  }
}
.desc-item {
  position: relative;
  &::before {
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-right: 12px;
    margin-bottom: 4px;
    vertical-align: middle;
    content: "";
    background-color: @main-gray-3;
    border-radius: 50%;
  }
}
.brand {
  padding-top: 64px;
  padding-bottom: 64px;
  &.tablet {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &.mobile {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
