@import '/app/src/theme/variables.module.less';

.brands {
  &.mobile {
    overflow-x: auto;
  }
}
.list {
  &.mobile {
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}
.item {
  margin-bottom: 16px;
  text-align: center;
  &.mobile {
    display: inline-block;
    flex: auto;
    width: 80px;
    margin-bottom: 0;
  }
}
