@import '/app/src/theme/variables.module.less';

.confirm-change-country {
  max-width: 350px;
  padding: 12px 16px;
  &-content {
    display: flex;
    margin-bottom: 12px;
    .icon {
      margin-right: 10px;
      line-height: 16px;
    }
  }
  &-actions {
    text-align: right;
  }
}
