@import '/app/src/theme/variables.module.less';

.cell-tab-panel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &-icon {
    display: flex;
    margin-right: 8px;
    color: @main-gray-5;
  }
  &-icon-active {
    color: @main-orange-8;
  }
  &-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .desc {
    color: @main-gray-5;
  }
  &-count {
    display: block;
    margin-left: 6px;
  }
}
