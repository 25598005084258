@import '/app/src/theme/variables.module.less';

.consent-cookies-list {
  .description {
    padding: 16px 0;
  }
  .text {
    padding-bottom: 8px;
  }
}
