@import '/app/src/theme/variables.module.less';

.cell-percentage {
  height: inherit;
}
.postfix {
  padding-left: 4px;
}
.disabled {
  color: @main-gray-4;
}
.skeleton {
  width: 100%;
  :global .ant-skeleton-input {
    width: 100%;
    min-width: 90px;
  }
}
