@import '/app/src/theme/variables.module.less';

/* stylelint-disable declaration-block-no-duplicate-custom-properties, no-invalid-position-at-import-rule */
@white: #ffffff;
@main-gray-4: #d0d3d6;
@main-gray-5: #b8bdc0;
@main-orange-8: #f57e2e;
@main-blue-gray-7: #59666e;
@main-blue-gray-9: #132530;
:global {
  .ant-checkbox {
    top: 0;
  }
  .ant-checkbox-inner {
    border-color: @main-gray-5;
    border-radius: 0;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @main-orange-8;
    border-color: @main-orange-8;
  }
  .ant-checkbox-checked::after {
    border-color: @main-orange-8;
    border-radius: 3px;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: @main-gray-4 !important;
    border-color: @main-gray-4 !important;
  }
  .ant-checkbox-disabled + span {
    color: @main-gray-4;
  }
  .ant-checkbox-disabled::after {
    border-color: @main-gray-4;
    border-radius: 2px;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: @main-orange-8;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: @white;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    border-color: @main-orange-8;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: @main-orange-8;
  }
}

/* stylelint-enable declaration-block-no-duplicate-custom-properties, no-invalid-position-at-import-rule */
.checkbox {
  color: inherit;
  &-size {
    &-small {
      :global {
        .ant-checkbox + span {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    &-middle {
      :global {
        .ant-checkbox {
          top: 3px;
        }
        .ant-checkbox + span {
          position: relative;
          top: 0.2em;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
    &-large {
      :global {
        .ant-checkbox + span {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
  &-color {
    &-base {
      :global {
        .ant-checkbox + span {
          color: @main-blue-gray-9;
        }
      }
    }
  }
  &-align {
    &-middle {
      align-items: center;
    }
    &-top {
      align-items: flex-start;
    }
    &-bottom {
      align-items: flex-end;
    }
  }
}
